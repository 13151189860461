import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import TabGroup from 'lib/components/Tabs';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { cdnIfy } from 'lib/helpers';
import { OrderModel } from 'lib/model/objects/orderModel';
import { dbToUICurrencyString } from 'lib/pricing/ui';
import { safeAsync, safeGetOrThrow } from 'lib/safeWrappers';
import { ESnapshotExists, Event } from 'lib/types';
import {
  OrderCancelledEvent,
  OrderEditedEvent,
  OrderInvoiceCreatedEvent,
  OrderInvoicePaidEvent,
  OrderInvoicePaymentNoteEvent,
  OrderInvoiceRefundedEvent,
  OrderNewspaperOrderConfirmedEvent,
  OrderObituaryVerifiedEvent
} from 'lib/types/events';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import moment from 'moment';
import { selectActiveOrganization, selectIsPublisher } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import { getBooleanFlag } from 'utils/flags';
import { getLogger } from 'utils/logger';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';

type OrderActivityLogProps = {
  order: OrderModel;
};

const ACTIVITY_LOG_DETAIL_TAB = {
  enabled: true,
  label: 'Activity',
  id: 'activity'
};

type SerializedEvent = {
  label: string;
  description?: React.ReactNode;
};

type OrderActivityLogEventProps = {
  serializedEvent: SerializedEvent;
  timestamp: Event['createdAt'];
};
function OrderActivityLogEvent({
  serializedEvent,
  timestamp
}: OrderActivityLogEventProps) {
  const { description, label } = serializedEvent;
  const activeOrganization = useAppSelector(selectActiveOrganization);

  return (
    <div className="pt-6 pb-8 flex justify-between gap-2">
      <div className="text-sm">
        <div className="font-medium">{label}</div>
        {description && (
          <div className="text-column-gray-400">{description}</div>
        )}
      </div>
      <div className="text-xs text-column-gray-400 w-56 text-right">
        {moment(timestamp.toMillis())
          .tz(activeOrganization?.data()?.iana_timezone || 'America/Chicago')
          .format("D MMM 'YY [at] LT z")}
      </div>
    </div>
  );
}

const serializeOrderEvent = async (
  orderEvent: ESnapshotExists<Event>,
  isPublisher: boolean
): Promise<ResponseOrError<SerializedEvent | null>> => {
  switch (orderEvent.data().type) {
    case 'order.invoices.paid':
    case 'order.invoices.paid_out_of_platform': {
      const {
        data: { invoice }
      } = orderEvent.data() as OrderInvoicePaidEvent;

      const [invoiceSnapshotError, invoiceSnapshot] = await safeGetOrThrow(
        invoice
      );
      if (invoiceSnapshotError) {
        return wrapError(invoiceSnapshotError);
      }

      const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
      const amountPaid = invoiceSnapshot.data().amount_paid;
      const receiptUrl = invoiceSnapshot.data().receipt_pdf;
      return wrapSuccess({
        label: isPublisher ? 'Invoice paid by advertiser' : 'Invoice paid',
        description: (
          <>
            {invoiceSnapshot.data().customer_email} paid the invoice for $
            {dbToUICurrencyString(amountPaid || 0)}.
            {receiptUrl && (
              <>
                {' View '}
                <a
                  className="underline"
                  href={cdnIfy(receiptUrl, {
                    useColumnCDN
                  })}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  receipt here
                </a>
              </>
            )}
          </>
        )
      });
    }
    case 'order.edited': {
      const {
        data: { editedBy }
      } = orderEvent.data() as OrderEditedEvent;
      const { response: editor, error: editorError } = await safeGetOrThrow(
        editedBy
      );
      if (editorError) {
        return wrapError(editorError);
      }
      if (isPublisher) return wrapSuccess(null);
      return wrapSuccess({
        label: 'Order edited',
        description: `Edited by ${editor.data().email}`
      });
    }
    case 'order.invoices.created': {
      const {
        data: { orderInvoice }
      } = orderEvent.data() as OrderInvoiceCreatedEvent;
      const { response: invoiceSnapshot, error: invoiceSnapshotError } =
        await safeGetOrThrow(orderInvoice);
      if (invoiceSnapshotError) {
        return wrapError(invoiceSnapshotError);
      }
      return wrapSuccess({
        label: isPublisher
          ? 'Invoice sent to advertiser'
          : 'Invoice created by publisher',
        description: (
          <>
            {invoiceSnapshot.data().customer_email} received an invoice
            {!isPublisher && (
              <>
                {' '}
                for $
                {dbToUICurrencyString(
                  invoiceSnapshot.data().pricing?.totalInCents || 0
                )}
              </>
            )}
            .
          </>
        )
      });
    }
    case 'order.invoices.refunded': {
      const {
        data: { refundAmount, refundedBy, refundReason }
      } = orderEvent.data() as OrderInvoiceRefundedEvent;
      const { response: refunder, error: refunderError } = await safeGetOrThrow(
        refundedBy
      );
      if (refunderError) {
        return wrapError(refunderError);
      }
      if (isPublisher) return wrapSuccess(null);
      return wrapSuccess({
        label: `Order refunded by ${refunder.data().email}`,
        description: `Order refunded for ${refundAmount} by ${
          refunder.data().name
        }. "${refundReason}"`
      });
    }
    case 'order.cancelled': {
      const {
        data: { cancelledByEmail, cancellationReason }
      } = orderEvent.data() as OrderCancelledEvent;
      return wrapSuccess({
        label: `Order cancelled by ${cancelledByEmail}`,
        description: cancellationReason
      });
    }
    case 'order.obituary.verified': {
      const { data: verificationData } =
        orderEvent.data() as OrderObituaryVerifiedEvent;

      if (verificationData.verificationMethod === 'automatic') {
        return wrapSuccess({
          label: 'Order verified automatically'
        });
      }
      const { verifiedBy } = verificationData;
      let verifiedByEmail: string;
      if (typeof verifiedBy === 'string') {
        verifiedByEmail = verifiedBy;
      } else {
        const { response: verifier, error: verifierError } =
          await safeGetOrThrow(verifiedBy);
        if (verifierError) {
          return wrapError(verifierError);
        }
        verifiedByEmail = verifier.data().email;
      }
      return wrapSuccess({
        label: 'Order verified manually',
        description: `Verified by ${verifiedByEmail}`
      });
    }
    case 'order.newspaper_order.confirmed': {
      const {
        data: { confirmedBy, newspaperOrder }
      } = orderEvent.data() as OrderNewspaperOrderConfirmedEvent;
      const { response: confirmer, error: confirmerError } =
        await safeGetOrThrow(confirmedBy);
      if (confirmerError) {
        return wrapError(confirmerError);
      }
      const {
        response: newspaperOrderSnapshot,
        error: newspaperOrderSnapshotError
      } = await safeGetOrThrow(newspaperOrder);
      if (newspaperOrderSnapshotError) {
        return wrapError(newspaperOrderSnapshotError);
      }
      const { response: newspaper, error: newspaperError } =
        await safeGetOrThrow(newspaperOrderSnapshot.data().newspaper);
      if (newspaperError) {
        return wrapError(newspaperError);
      }
      return wrapSuccess({
        label: `Confirmed for ${newspaper.data().name}`,
        description: `Order confirmed by ${confirmer.data().email}`
      });
    }
    case 'order.invoices.paid.note': {
      const {
        data: { note }
      } = orderEvent.data() as OrderInvoicePaymentNoteEvent;

      return wrapSuccess({
        label: isPublisher
          ? 'Advertiser paid invoice by cash or check'
          : 'Invoice paid',
        description: `Recorded with payment note: ${note}`
      });
    }
    default: {
      return wrapSuccess(null);
    }
  }
};

type ParsedEvent = {
  serializedEvent: SerializedEvent;
  id: string;
  timestamp: Event['createdAt'];
};
const generateSyntheticEvents = async (
  order: OrderModel,
  isPublisher: boolean
): Promise<ResponseOrError<ParsedEvent[]>> => {
  const [newspaperOrdersError, newspaperOrders] = await safeAsync(() =>
    order.getNewspaperOrders()
  )();
  if (newspaperOrdersError) return wrapError(newspaperOrdersError);
  const newspaperResponses = await Promise.all(
    newspaperOrders.map(newspaperOrder =>
      safeGetOrThrow(newspaperOrder.modelData.newspaper)
    )
  );
  const newspapers = [];
  for (const [pullNewspaperError, newspaper] of newspaperResponses) {
    if (pullNewspaperError) return wrapError(pullNewspaperError);
    newspapers.push(newspaper);
  }

  return wrapSuccess([
    {
      serializedEvent: {
        label: isPublisher
          ? 'Order received'
          : `Order submitted to ${newspapers
              .map(newspaper => newspaper.data().name)
              .join(', ')}`
      },
      timestamp: order.modelData.createdAt,
      id: 'order-submitted'
    }
  ]);
};

function OrderActivityLog({ order }: OrderActivityLogProps) {
  const isPublisher = useAppSelector(selectIsPublisher);

  const eventQuery = useFirestoreQueryListener(
    getFirebaseContext().eventsRef().where('order', '==', order.ref),
    [order.id]
  );

  const { value: serializedEvents, isLoading } = useAsyncEffect({
    fetchData: async () => {
      if (!eventQuery) return;
      const serializedEventResponses = await Promise.all(
        eventQuery.docs.map(async event => {
          const { response: serializedEvent, error: serializedEventError } =
            await serializeOrderEvent(event, isPublisher);
          if (serializedEventError) {
            getLogger().info('Failed to serialize event', {
              eventId: event.id
            });
            return wrapError(serializedEventError);
          }
          return wrapSuccess({
            serializedEvent,
            timestamp: event.data().createdAt,
            id: event.id
          });
        })
      );
      const serializedEvents = serializedEventResponses
        .filter(response => !response.error)
        .map(({ response: parsedEvent }) => parsedEvent) as ParsedEvent[];
      const realEvents = serializedEvents.filter(
        ({ serializedEvent }) => !!serializedEvent
      );
      const [syntheticEventsError, syntheticEvents] =
        await generateSyntheticEvents(order, isPublisher);
      if (syntheticEventsError) {
        getLogger().warn(
          'Unable to generate synthetic events for order activity log'
        );
        return [];
      }
      return realEvents
        .concat(syntheticEvents)
        .sort((a, b) =>
          a.timestamp.toMillis() > b.timestamp.toMillis() ? 1 : -1
        );
    },
    dependencies: [eventQuery?.docs.map(d => d.id).join(',')]
  });

  return (
    <div className="h-full flex flex-col">
      <TabGroup
        onClickTab={() => null}
        activeTab={ACTIVITY_LOG_DETAIL_TAB}
        tabs={[ACTIVITY_LOG_DETAIL_TAB]}
        id="notice-detail-drawer"
      />
      <div className="flex-1 flex flex-col">
        <div className="w-full flex-1">
          {isLoading || !serializedEvents ? (
            <div className="pt-12">
              <LoadingSpinner />
            </div>
          ) : (
            <ul className="divide-y divide-gray-200 px-6">
              {serializedEvents.map(({ serializedEvent, timestamp, id }) => (
                <OrderActivityLogEvent
                  serializedEvent={serializedEvent}
                  timestamp={timestamp}
                  key={id}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderActivityLog;

import api from 'api';
import { apiPost } from 'api/typed';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import ToastActions from 'redux/toast';
import { selectUser } from 'redux/auth';
import { AppAsyncThunk } from 'redux/types';
import { TableLayout } from 'lib/components/TableLayout';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import {
  ArchivableTableProps,
  TableHeader
} from 'lib/components/TableLayout/types';
import { SearchableOrderRecord } from 'lib/types/searchable';
import { useMemo, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { EOrganization, ERequestTypes, ESnapshot } from 'lib/types';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { isDefined } from 'lib/helpers';
import { push } from 'connected-react-router';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Product } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import {
  DEFAULT_ORDER_FILTER,
  OrderFilter,
  getOrderFilters
} from './filters/helpers';
import AdsTableFilterDialog from './AdsTableFilterDialog';
import AdsTableRow from './AdsTableRow';
import { AdsTableColumns } from './types';
import { DRAFT_ORDERS_TAB } from './tabs';

type AdsTableProps = {
  product: Product;
  ads: SearchableOrderRecord[];
  tabs: TabOption[];
  columns: AdsTableColumns[];
  header: TableHeader;
  loading: boolean;
  activeOrganization: ESnapshot<EOrganization> | null;
  isPublisher: boolean;
  selectedAdTableTab: TabOption;
  setSelectedAdTableTab: (tab: TabOption) => void;
  setSearchTerm: (search: string) => void;
  rowFilter: OrderFilter;
  setRowFilter: (filter: OrderFilter) => void;
  refreshTableData: () => void;
};

function AdsTable({
  product,
  ads,
  tabs,
  columns,
  header,
  loading,
  activeOrganization,
  isPublisher,
  selectedAdTableTab,
  setSelectedAdTableTab,
  setSearchTerm,
  rowFilter,
  setRowFilter,
  refreshTableData
}: AdsTableProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const filtersToShow = useMemo<Record<keyof OrderFilter, boolean>>(
    () => ({
      status: columns.includes(AdsTableColumns.STATUS),
      filingType: !isPublisher || product === Product.Classified,
      customerType: isPublisher && product === Product.Obituary,
      verification: isPublisher && product === Product.Obituary,
      publicationDate: true
    }),
    [isPublisher, columns.join(','), product]
  );

  const [editedRowFilter, setEditedRowFilter] = useState(rowFilter);

  const { value: filterFacets } = useAsyncEffect({
    fetchData: async () => {
      if (!user) {
        return null;
      }

      const req: ERequestTypes['search/orders/facets'] = {
        search: '',
        filters: getOrderFilters({
          product,
          activeOrganization,
          user,
          selectedAdTableTab,
          tableFilters: {},
          isPublisher,
          showAllOrgsNotices: undefined,
          allowedOrganizations: undefined
        }),
        facets: {
          newspaperorderstatus: {
            type: 'value',
            name: 'confirmationstatus'
          },
          filingtype: {
            type: 'value',
            name: 'publicationcategory'
          }
        }
      };

      const facetResults = await apiPost('search/orders/facets', req);
      if (facetResults.error) {
        logAndCaptureException(
          ColumnService.ORDER_MANAGEMENT,
          facetResults.error,
          'Could not load confirmation status facets for orders',
          {
            activeOrganizationId: activeOrganization?.id,
            service: ColumnService.ORDER_MANAGEMENT
          }
        );
        return null;
      }

      return facetResults.response;
    },
    dependencies: [
      activeOrganization?.id,
      user?.id,
      selectedAdTableTab.id,
      isPublisher
    ]
  });

  const confirmationStatuses = filterFacets?.newspaperorderstatus[0].data.map(
    d => d.value
  ) as NewspaperOrderStatus[] | undefined;
  const publicationCategories = filterFacets?.filingtype[0].data.map(
    d => d.value
  ) as string[] | undefined;

  const isDraftsTab = selectedAdTableTab.id === DRAFT_ORDERS_TAB.id;
  const showDeleteAction =
    useBooleanFlag(LaunchDarklyFlags.ENABLE_DRAFT_ORDER_DELETION) &&
    isDraftsTab;

  const archivable:
    | ArchivableTableProps<SearchableOrderRecord>
    | undefined = showDeleteAction
    ? {
        onArchive: async orderRecord => {
          await dispatch(deleteDraftOrder(orderRecord.orderid));
          // Wait for 3 seconds to allow Elastic sync to complete
          await new Promise(resolve => {
            setTimeout(resolve, 3000);
          });
          refreshTableData();
        },
        renderWarning: () => ({
          header: 'Delete Draft',
          body: 'Are you sure you want to delete this draft?',
          buttonText: 'Delete'
        }),
        isArchiveDisabled: orderRecord => {
          // Only the original author of the draft can delete it
          const isUserOwnerOfOrder = orderRecord.authorizeduserid === user?.id;
          return !isUserOwnerOfOrder;
        }
      }
    : undefined;

  return (
    <main className="bg-white sm:rounded-lg border border-column-gray-100 shadow-column-2">
      <TabGroup
        onClickTab={setSelectedAdTableTab}
        activeTab={selectedAdTableTab}
        tabs={tabs}
        id="ads-table-tabs"
      />
      <TableLayout
        id="ads-table"
        header={header}
        columns={columns}
        data={ads}
        archivable={archivable}
        clickable={{
          onClick: item => {
            if (item.newspaperorderstatus === NewspaperOrderStatus.DRAFT) {
              if (item.ordertype === Product.Obituary) {
                dispatch(push(`/obituaries/place/${item.orderid}`));
              } else if (item.ordertype === Product.Classified) {
                dispatch(push(`/classifieds/place/${item.orderid}`));
              } else {
                logAndCaptureMessage(
                  'Attempted to access placement flow for unimplemented order type',
                  {
                    orderType: item.ordertype
                  }
                );
              }
            } else {
              dispatch(
                push(`/${item.ordertype.toLowerCase()}/${item.orderid}`)
              );
            }
          }
        }}
        filterable={{
          shouldShowTableItem: () => true,
          searchEnabled: true,
          onSearch: setSearchTerm,
          additionalFilters: {
            applyFilterChanges: () => setRowFilter(editedRowFilter),
            resetFilters: () => {
              setRowFilter(DEFAULT_ORDER_FILTER);
              setEditedRowFilter(DEFAULT_ORDER_FILTER);
            },
            filterHasChanges:
              safeStringify(rowFilter) !== safeStringify(editedRowFilter),
            numFiltersActive: Object.values(rowFilter).filter(isDefined).length,
            onDialogOpen: () => setEditedRowFilter(rowFilter),
            renderDialog: () => (
              <AdsTableFilterDialog
                filtersToShow={filtersToShow}
                confirmationStatuses={confirmationStatuses ?? []}
                publicationCategories={publicationCategories ?? []}
                rowFilter={rowFilter}
                editedRowFilter={editedRowFilter}
                setEditedRowFilter={setEditedRowFilter}
              />
            )
          }
        }}
        renderRow={item => <AdsTableRow item={item} columns={columns} />}
        loading={loading}
      />
    </main>
  );
}

function deleteDraftOrder(orderId: string): AppAsyncThunk {
  return async dispatch => {
    const result = await api.safePostWithParams<'orders/:orderId/delete'>(
      `orders/${orderId}/delete`
    );

    if (result.error) {
      logAndCaptureException(
        ColumnService.ORDER_MANAGEMENT,
        result.error,
        'Could not delete draft order',
        {
          orderId
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Error deleting draft',
          bodyText: 'Could not delete draft. Please try again.'
        })
      );
      return;
    }

    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Draft deleted',
        bodyText: 'Draft has been successfully deleted.'
      })
    );
  };
}

export default AdsTable;

import React from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ActionsPopover } from 'lib/components/ActionsPopover';
import { ColumnButton } from 'lib/components/ColumnButton';
import { push } from 'connected-react-router';
import { useSafeLoading } from 'lib/components/hooks/useSafeLoading';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ColumnService } from 'lib/services/directory';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromRef } from 'lib/model';
import { selectUser } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { logError } from 'utils/logger';
import ToastActions from 'redux/toast';
import { safeAsync } from 'lib/safeWrappers';
import { duplicateOrder } from './place/helpers/duplicateOrder';

type OrderActionOptionProps = {
  onClick: () => void;
};

function AdsTableActionsColumn({ onClick }: OrderActionOptionProps) {
  return (
    <ColumnButton
      type="button"
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onClick();
      }}
      buttonText="Duplicate"
      startIcon={<DocumentDuplicateIcon className="w-4 h-4" />}
      border="none"
    />
  );
}

type OrderTableRowActionsProps = {
  orderId: string;
};

export default function OrderTableRowActions({
  orderId
}: OrderTableRowActionsProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const [orderIsDuplicating, handleDuplicateOrderClick] = useSafeLoading({
    callback: async (orderId: string) => {
      const orderRef = getFirebaseContext().ordersRef().doc(orderId);

      const { response: orderModel, error } = await safeAsync(async () => {
        return getModelFromRef(OrderModel, getFirebaseContext(), orderRef);
      })();
      if (error) {
        logError(`Error getting order model: ${error}`);
        return;
      }

      const { response: newOrderRef, error: newOrderRefError } =
        await duplicateOrder(orderModel, user);

      if (newOrderRefError) {
        dispatch(
          ToastActions.toastError({
            headerText: 'Error',
            bodyText: 'Error duplicating order. Please try again.'
          })
        );
        logError(`Error duplicating order: ${newOrderRefError}`);
        return;
      }

      dispatch(
        push(
          `/${PRODUCT_TO_NAME[
            orderModel.modelData.product
          ].plural.toLowerCase()}/place/${newOrderRef.id}`
        )
      );
    },
    errorConfig: {
      service: ColumnService.ORDER_MANAGEMENT,
      message: 'Error duplicating order',
      tags: {
        orderId
      }
    }
  });

  if (orderIsDuplicating) {
    return (
      <div className="ml-8">
        <LoadingSpinner inline size="sm" />
      </div>
    );
  }

  return (
    <ActionsPopover id={`actions-popover-${orderId}`}>
      <AdsTableActionsColumn
        onClick={() => handleDuplicateOrderClick(orderId)}
      />
    </ActionsPopover>
  );
}
